import { Link, useHistory, generatePath } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Dashboard from '../Dashboard/Dashboard';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({}));
export default function DashboardTransferenciasFilter() {
	return <Dashboard transferenciasFilter />;
}
