import 'react-credit-cards/es/styles-compiled.css';

import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Cards from 'react-credit-cards';
import InputMask from 'react-input-mask';

const CardRegistration = ({
	linkPagamentoPagar,
	setLinkPagamentoPagar,
	handlePost,
	errosLink,
	limiteParcelas,
}) => {
	const [validade, setValidade] = useState('');

	const handleSetValidade = (e) => {
		const [mes, ano] = e.target.value.split('/');
		return (
			setValidade(e.target.value),
			setLinkPagamentoPagar({
				...linkPagamentoPagar,
				cartao: {
					...linkPagamentoPagar.cartao,
					mes: parseInt(mes),
					ano: ano,
				},
			})
		);
	};

	const [parcelasArray, setParcelasArray] = useState([]);

	useEffect(() => {
		if (limiteParcelas) {
			const arrayParcelas = [];
			for (var i = 1; i < limiteParcelas + 1; i++) {
				arrayParcelas.push(i);
			}
			setParcelasArray(arrayParcelas);
		}
	}, [limiteParcelas]);

	return (
		<Box display="flex" flexDirection="column">
			<Box marginBottom="12px" marginTop="24px">
				<Cards
					cvc={linkPagamentoPagar.cartao.cvv}
					expiry={validade}
					focused={linkPagamentoPagar.cartao.focus}
					name={linkPagamentoPagar.cartao.nome}
					number={linkPagamentoPagar.cartao.numero}
					placeholders={{ name: 'NOME DO TITULAR' }}
				/>
			</Box>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<InputMask
						name="number"
						mask="9999 9999 9999 9999"
						maskChar=" "
						value={linkPagamentoPagar.cartao.numero}
						onFocus={(e) =>
							setLinkPagamentoPagar({
								...linkPagamentoPagar,
								cartao: {
									...linkPagamentoPagar.cartao,
									focus: e.target.name,
								},
							})
						}
						onChange={(e) =>
							setLinkPagamentoPagar({
								...linkPagamentoPagar,
								cartao: {
									...linkPagamentoPagar.cartao,
									numero: e.target.value,
								},
							})
						}
					>
						{() => (
							<TextField
								variant="outlined"
								error={errosLink['cartao.numero']}
								helperText={errosLink['cartao.numero'] ? errosLink['cartao.numero'].join(' ') : null}
								name="number"
								required
								fullWidth
								label="Número do Cartão"
							/>
						)}
					</InputMask>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						error={errosLink['cartao.nome']}
						helperText={errosLink['cartao.nome'] ? errosLink['cartao.nome'].join(' ') : null}
						value={linkPagamentoPagar.cartao.nome}
						onFocus={(e) =>
							setLinkPagamentoPagar({
								...linkPagamentoPagar,
								cartao: {
									...linkPagamentoPagar.cartao,
									focus: e.target.name,
								},
							})
						}
						onChange={(e) =>
							setLinkPagamentoPagar({
								...linkPagamentoPagar,
								cartao: {
									...linkPagamentoPagar.cartao,
									nome: e.target.value,
								},
							})
						}
						required
						name="name"
						fullWidth
						label="Nome do títular"
					/>
				</Grid>

				<Grid item xs={12} sm={3}>
					<InputMask
						value={validade}
						onChange={(e) => handleSetValidade(e)}
						onFocus={(e) =>
							setLinkPagamentoPagar({
								...linkPagamentoPagar,
								cartao: {
									...linkPagamentoPagar.cartao,
									focus: e.target.name,
								},
							})
						}
						name="expiry"
						mask="99/9999"
					>
						{() => (
							<TextField
								variant="outlined"
								error={errosLink['cartao.mes'] + errosLink['cartao.ano']}
								helperText={
									errosLink['cartao.mes']
										? errosLink['cartao.mes'].join(' ')
										: null || errosLink['cartao.ano']
										? errosLink['cartao.ano'].join(' ')
										: null
								}
								name="expiry"
								required
								fullWidth
								label="Validade"
							/>
						)}
					</InputMask>
				</Grid>

				<Grid item xs={12} sm={3}>
					<InputMask
						name="cvv"
						value={linkPagamentoPagar.cartao.cvv}
						onFocus={(e) =>
							setLinkPagamentoPagar({
								...linkPagamentoPagar,
								cartao: {
									...linkPagamentoPagar.cartao,
									focus: e.target.name,
								},
							})
						}
						onChange={(e) =>
							setLinkPagamentoPagar({
								...linkPagamentoPagar,
								cartao: {
									...linkPagamentoPagar.cartao,
									cvv: e.target.value,
								},
							})
						}
						mask="999"
						maskChar=" "
					>
						{() => (
							<TextField
								variant="outlined"
								name="cvc"
								error={errosLink['cartao.cvv']}
								helperText={errosLink['cartao.cvv'] ? errosLink['cartao.cvv'].join(' ') : null}
								required
								fullWidth
								label="CVV"
							/>
						)}
					</InputMask>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth error={errosLink.parcelas}>
						<InputLabel>Parcelas</InputLabel>
						<Select
							fullWidth
							value={linkPagamentoPagar.parcelas}
							onChange={(e) =>
								setLinkPagamentoPagar({
									...linkPagamentoPagar,
									parcelas: e.target.value,
								})
							}
						>
							{parcelasArray.map((parcela) => (
								<MenuItem value={parcela} key={parcela}>
									{parcela}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
};

export default CardRegistration;
